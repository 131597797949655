/*.trailerHistoryYAxis path {
  display: none;
}

.trailerHistoryYAxis line{
  stroke: white;
  stroke-opacity: 0.3;
}*/

.trailerHistoryAxis line{
  stroke: #909090;
}

.trailerHistoryAxis path{
  stroke: #909090;
}

.trailerHistoryAxis text{
  fill: #909090;
}  