.container {
  /*padding: 24px;*/
}

.column {
  font-size: 10px;
  min-width: 50px;
}

.confidenceColumn {
  width: 120px;
}

.dimensionColumn {
  /*width: 150px;*/
}

.matchedTripId {
  /*width: 300px;*/
}