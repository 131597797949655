  .jetpack-table-custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.jetpack-table-custom-filter-dropdown input {
  width: 200px;
  margin-right: 8px;
}

.jetpack-table-custom-filter-dropdown button {
  margin-right: 8px;
}

.jetpack-table-highlight {
  background-color: transparent;
  padding: 0;
  color: dodgerblue;
}